import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Flex,
    Spinner,
    Button,
    IconButton,
    Box,
    Grid,
    HStack,
    Circle,
    Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import FmipRefreshSvg from '../../assets/FmipRefreshSvg';
import { fetchEnrollmentVideos } from '../../services/apiService';
import BorderedBox from '../layouts/BorderedBox';
import VerticalCenter from '../layouts/VerticalCenter';
import VideoPlayerSkeleton from './media-gallery/VideoPlayerSkeleton';
import { HardwareResults } from '../../config/interface';

interface ReviewVideosProps {
    recordId: string;
    reviewer: string;
    reviewStatus: string;
    hardwareResults?: HardwareResults;
    enrollmentData?: any;
}

const ReviewVideos: React.FC<ReviewVideosProps> = ({
    recordId,
    reviewer,
    reviewStatus,
    hardwareResults,
    enrollmentData,
}) => {
    const [scrollIndex, setScrollIndex] = useState(0);

    const useFetchVideos: any = () => {
        return useQuery(['videos', recordId], () => fetchEnrollmentVideos(recordId));
    };

    const {
        data: enrollmentVideos,
        isFetching,
        error,
        refetch: refetchVideos,
    } = useFetchVideos();

    const handleRetryFetchVideos = () => {
        refetchVideos();
    };

    const hardwareTestsToPass = ['digitizer', 'audio', 'backCamera', 'frontCamera'];
    const formattedKey = {
        digitizer: 'Digitizer',
        audio: 'Audio',
        backCamera: 'Back Camera',
        frontCamera: 'Front Camera',
    };

    const hardwareResultSummary = useMemo(() => {
        if (!hardwareResults) {
            return [];
        }
        const results = hardwareTestsToPass.map(test => {
            const isFunctional =
                Array.isArray(hardwareResults) &&
                hardwareResults[0].hardwareResults[test];
            const value = isFunctional ? 'Functional' : 'Non-functional';
            const color = isFunctional ? 'green' : 'red';

            return {
                key: formattedKey?.[test] || test,
                value,
                color,
            };
        });

        return results;
    }, [hardwareResults]);

    return (
        <BorderedBox styleProps={{ p: 0 }}>
            <Flex direction="row">
                {false && (
                    <Flex
                        id={`videos-scroll-container-${recordId}`}
                        w="calc(100% - 200px)"
                        minHeight="200px"
                        direction="row"
                        m="24px"
                        overscrollBehaviorX="contain"
                        scrollSnapType="x mandatory"
                        overflowX="auto"
                        css={{
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {isFetching && (
                            <VerticalCenter
                                innerProps={{ w: 'auto', m: 'auto', borderRadius: '8px' }}
                                h={'100%'}
                                m={'auto'}
                            >
                                <Spinner variant={'pageLoader'} />
                            </VerticalCenter>
                        )}
                        {!isFetching &&
                            !error &&
                            enrollmentVideos.length !== 0 &&
                            enrollmentVideos[0] !== null &&
                            enrollmentVideos.map((video, idx) => (
                                <VideoPlayerSkeleton
                                    key={`video-skeleton-${video.title}-${idx}`}
                                    video={video}
                                    idx={idx}
                                    recordId={recordId}
                                    reviewer={reviewer}
                                    reviewStatus={reviewStatus}
                                />
                            ))}
                        {!isFetching &&
                            !error &&
                            (enrollmentVideos.length === 0 ||
                                (enrollmentVideos.length > 0 &&
                                    enrollmentVideos[0] === null)) && (
                                <IconButton
                                    aria-label="reload videos"
                                    icon={<FmipRefreshSvg />}
                                    variant="transparent"
                                    w="50px"
                                    h="50px"
                                    minH="unset"
                                    minW="unset"
                                    m="auto"
                                    onClick={handleRetryFetchVideos}
                                />
                            )}
                    </Flex>
                )}

                {false && !isFetching && !error && enrollmentVideos.length > 4 && (
                    <Flex alignItems="center">
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (scrollIndex >= 1) {
                                    document
                                        .getElementById(
                                            `videos-scroll-container-${recordId}`
                                        )
                                        ?.scrollTo({
                                            left: (scrollIndex - 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex - 1);
                                }
                            }}
                        >
                            <ChevronLeftIcon boxSize={10} />
                        </Button>
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (scrollIndex < enrollmentVideos.length - 4) {
                                    document
                                        .getElementById(
                                            `videos-scroll-container-${recordId}`
                                        )
                                        ?.scrollTo({
                                            left: (scrollIndex + 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex + 1);
                                }
                            }}
                        >
                            <ChevronRightIcon boxSize={10} />
                        </Button>
                    </Flex>
                )}
                {enrollmentData[0]?.method?.toUpperCase() !== 'LITE' &&
                    hardwareResultSummary.length > 0 && (
                        <Flex
                            flexDir="column"
                            w="full"
                            maxW="container.sm"
                            bg="#F0F0F5"
                            borderRadius={8}
                            p={4}
                            border="1px solid"
                            borderColor="#D5D6DA"
                            m={4}
                        >
                            <Box
                                bg="black"
                                py={1}
                                px={2}
                                borderRadius={8}
                                color="white"
                                w="fit-content"
                            >
                                Hardware Results
                            </Box>

                            <Grid
                                mt={4}
                                rowGap={4}
                                columnGap={6}
                                gridTemplateColumns="minmax(120px, auto) 1fr"
                            >
                                {hardwareResultSummary?.map(({ key, value, color }) => (
                                    <React.Fragment key={key}>
                                        <Text fontWeight={700}>{key}</Text>
                                        <HStack alignItems="flex-start">
                                            <Circle size="20px" bg={`${color}.500`} />
                                            <Text fontWeight={700}>{value}</Text>
                                        </HStack>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Flex>
                    )}
            </Flex>
        </BorderedBox>
    );
};

export default ReviewVideos;
